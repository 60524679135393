import React from 'react'
import PropTypes from 'prop-types'

import { browserHistory } from 'react-router'

import { connect } from 'react-redux'
import { logoutUser } from '../redux/actions/userActions'

import logo from '../images/logo.png'

class MenuLateral extends React.Component{

    rederigir (e){
        e.preventDefault ()

        let id = e.target.id

        if (id === 'salir'){
            this.props.logoutUser ()
        }else if(id === 'menu'){
            browserHistory.push ('/')
        } else {
            browserHistory.push('/' + id)
        }
    }

    render (){
        return (
            <div className='menu-lateral'>
                <div className='accordion' id='accordionMenu'>
                    <div className='card'>
                        <div className='card-header' id='menuHeading'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link' type='button' data-target='#menu' aria-expanded='true' aria-controls='menu'
                                    id='menu' onClick={this.rederigir.bind(this)}>
                                    <img src={logo} alt='logo'/>
                                </button>
                            </h2>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-header' id='contactoHeading'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link' type='button' data-toggle='collapse' data-target='#collapseContacto' aria-expanded='true' aria-controls='collapseContacto'
                                    id='contacto' onClick={this.rederigir.bind(this)}>
                                    Tus datos
                                </button>
                            </h2>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-header' id='slidersHeading'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link' type='button' data-toggle='collapse' data-target='#collapseSliders' aria-expanded='true' aria-controls='collapseSliders'
                                    id='sliders' onClick={this.rederigir.bind(this)}>
                                    Sliders
                                </button>
                            </h2>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-header' id='proyectoHeading'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link' type='button' data-toggle='collapse' data-target='#collapseProyectos' aria-expanded='true' aria-controls='collapseProyectos'
                                    id='proyectos' onClick={this.rederigir.bind(this)}>
                                    Proyectos
                                </button>
                            </h2>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-header' id='serviciosHeading'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link' type='button' data-toggle='collapse' data-target='#collapseServicios' aria-expanded='true' aria-controls='collapseServicios'
                                    id='servicios' onClick={this.rederigir.bind(this)}>
                                    Servicios
                                </button>
                            </h2>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-header' id='sliderHeading'>
                            <h2 className='mb-0'>
                                <button className='btn btn-link' type='button' data-target='#slider' aria-expanded='true' aria-controls='slider'
                                    id='salir' onClick={this.rederigir.bind(this)}>
                                    SALIR
                                </button>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
})

MenuLateral.propTypes = {
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired
}

const mapActionsToProps = {
    logoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(MenuLateral)