import React from 'react'
import PropTypes from 'prop-types'

import { browserHistory } from 'react-router'

import { connect } from 'react-redux'
import { logoutUser } from '../redux/actions/userActions'

import MenuLateral from './menulateral'
import Footer from './footer'

import home from '../images/iconos/home.png'
import logout from '../images/iconos/logout.png'

class AdminApp extends React.Component {

    menu(e) {
        e.preventDefault()

        browserHistory.push('/')
    }

    salir(e) {
        e.preventDefault()

        this.props.logoutUser()
    }

    render() {
        let childs = this.props.children
            && React.cloneElement(this.props.children)

        return (
            <div className='adminapp'>
                <div className='board'>
                    <div className='row'>
                        <div className='col-0 col-sm-0 col-md-0 col-lg-0 col-xl-2 lateral'>
                            <MenuLateral />
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 childs'>
                            <div className='menu-principal'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                    <div className='row' onClick={this.menu.bind(this)}>
                                        <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 primero'>
                                            <img src={home} alt='home' />
                                        </div>
                                        <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 primero'>
                                            <h4>MENU PRINCIPAL</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6' onClick={this.salir.bind(this)}>
                                    <div className='row'>
                                        <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 primero'>
                                            <img src={logout} alt='home' />
                                        </div>
                                        <div className='col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 primero'>
                                            <h4>SALIR</h4>                                        </div>
                                    </div>

                                </div>
                            </div>
                            {childs}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

AdminApp.propTypes = {
    logoutUser: PropTypes.func.isRequired
}

const mapActionsToProps = {
    logoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(AdminApp)

