import {
    LOADING_DATA_IMAGE,
    SET_DATA_IMAGE,
    SET_ERROR_IMAGE
} from '../types'

const initialState = {
    loadingimage: false,
    image: '',
    message: '',
    error: ''
}


export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA_IMAGE:
            return {
                ...state,
                loadingimage: true
            }
        case SET_DATA_IMAGE:
            return {
                loadingimage: false,
                ...action.payload
            }
        case SET_ERROR_IMAGE:
            return {
                loadingimages: false,
                ...action.payload
            }
        default: return state
    }
}