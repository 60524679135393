import React from 'react'
import update from 'immutability-helper'

import { browserHistory } from 'react-router'

class Servicios extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 'lista-servicios'
        }
    }

    rederigir(e) {
        e.preventDefault()

        let id = e.target.id

        if (id === 'lista-servicios') {
            browserHistory.push('/servicios')
        } else if (id === 'nuevo-servicio') {
            browserHistory.push('/servicios/nuevo-servicio')
        }

        this.setState(update(this.state, {
            tab: { $set: id }
        }))
    }

    render() {
        let childs = this.props.children
            && React.cloneElement(this.props.children)

        return (
            <div className='config'>
                <ul className='nav justify-content-center'>
                    <li id='lista-servicios' className={this.state.tab === 'lista-servicios' ? 'nav-item active' : 'nav-item'} onClick={this.rederigir.bind(this)}>
                        <a id='lista-servicios' className='nav-link' onClick={this.rederigir.bind(this)}>TUS SERVICIOS</a>
                    </li>
                    <li id='nuevo-servicio' className={this.state.tab === 'nuevo-servicio' ? 'nav-item active' : 'nav-item'} onClick={this.rederigir.bind(this)}>
                        <a id='nuevo-servicio' className='nav-link' onClick={this.rederigir.bind(this)}>NUEVO SERVICIO</a>
                    </li>
                </ul>
                {childs}
            </div>
        )
    }
}

export default Servicios