import {
    LOADING_DATA_MESSAGE,
    SET_DATA_MESSAGE,
    SET_ERROR_MESSAGE
} from '../types'

const initialState = {
    loadingmessage: false,
    message: '',
    images: [],
    error: ''
}


export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA_MESSAGE:
            return {
                ...state,
                loadingmessage: true
            }
        case SET_DATA_MESSAGE:
            return {
                loadingmessage: false,
                ...action.payload
            }
        case SET_ERROR_MESSAGE: 
            return {
                loadingmessage: false,
                ...action.payload
            }
        default: return state
    }
}