import React from 'react'
import { browserHistory } from 'react-router'

import sliders from '../images/iconos/sliders.png'
import contact from '../images/iconos/contact.png'
import servicios from '../images/iconos/servicios.png'
import proyectos from '../images/iconos/proyectos.png'

class Dashboard extends React.Component{

    rederigir(e) {
        e.preventDefault()

        let id = e.target.id

        browserHistory.push('/' + id)
    }
    
    render (){
        let pestañas = [
            { titulo: 'TUS DATOS', descripcion: 'DE CONTACTO A MOSTRAR EN LA WEB', image: contact, id: 'contacto' },
            { titulo: 'SLIDERS', descripcion: 'AGREGUE SUS IMAGENES AL SLIDER', image: sliders, id: 'sliders' },
            { titulo: 'SERVICIOS', descripcion: 'AGREGUE O MODIFIQUE LOS SERVICIOS QUE BRINDA', image: servicios, id: 'servicios' },
            { titulo: 'PROYECTOS', descripcion: 'AGREGUE O MODIFIQUE LOS PROYECTOS QUE A EJECUTADO', image: proyectos, id: 'proyectos' }
        ]

        let markuptab = (
            pestañas.map((datos, index) => {
                return (
                    <div key={index} className='card shadow-sm p-1 mb-3 bg-white rounded-circle' id={datos.id} onClick={this.rederigir.bind(this)}>
                        <div className='imagen-card' style={{ display: datos.image === '' ? 'none' : 'block' }} id={datos.id}>
                            <img src={datos.image} alt='' className='card-img-top' id={datos.id}  />
                        </div>
                        <div className='card-header' id={datos.id}>
                            <h5 id={datos.id}>{datos.titulo}</h5>
                        </div>
                        <div className='card-body' id={datos.id}>
                            <p id={datos.id}>{datos.descripcion}</p>
                        </div>
                    </div>
                )
            })
        )
        return (
            <div className='dashboard'>
                <h4>DESDE TU MENU LATERAL ENCONTRARÁS</h4>
                <div className='overflow-auto dashboard'>
                    <div className='row'>
                        {markuptab}
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard