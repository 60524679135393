import {
    LOADING_DATA_USER,
    SET_DATA_USER,
    SET_ERROR_USER,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
} from '../types'

const initialState = {
    authenticated: false,
    loading: false,
    credentials: {},
    message: '',
    error: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA_USER:
            return {
                ...state,
                loading: true
            }
        case SET_DATA_USER:
            return {
                loading: false,
                ...action.payload
            }
        case SET_ERROR_USER:
            return {
                loadingimages: false,
                ...action.payload
            }
        case SET_UNAUTHENTICATED:
            return initialState
        default: return state
    }
}