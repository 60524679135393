import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import { browserHistory } from 'react-router'

import { connect } from 'react-redux'
import {
    nuevoWebAdminProyecto, nuevaImagenWebAdmin
} from '../../redux/actions/dataActions'

import gallery from '../../images/iconos/gallery.png'

var $ = window.$

class NuevoProyecto extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            titulo: '',
            descripcion: '',
            image: '',
            indice: ''
        }
    }

    guardarproyecto(e) {
        e.preventDefault()
        const { images: { images } } = this.props

        let proyecto = this.state

        if (proyecto.indice === '' || images === '') {
            alert('Debe completar los campos obligatorios')
        } else {
            let data = {
                titulo: proyecto.titulo,
                descripcion: proyecto.descripcion,
                indice: proyecto.indice,
                image: images
            }

            console.log(data)
            this.props.nuevoWebAdminProyecto(data)

            this.setState(update(this.state, {
                titulo: { $set: '' },
                descripcion: { $set: '' },
                image: { $set: '' },
                indice: { $set: '' }
            }))

            window.scrollTo(0, 0)
        }
    }

    handleInputChange(e) {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value

        if (id === 'indice') {
            if (value !== '0') {
                this.setState(update(this.state, {
                    indice: { $set: value }
                }))
            }
        } else {
            this.setState(update(this.state, {
                [id]: { $set: value }
            }))
        }
    }

    handleImageChange(e) {
        const image = e.target.files[0]
        const formData = new FormData()
        formData.append('image', image, image.name)

        this.props.nuevaImagenWebAdmin(formData)
    }

    handleEditPicture(e) {
        const fileInput = document.getElementById('imageInput')
        fileInput.click()
    }

    render() {
        const { message: { message, loadingmessage } } = this.props
        const { images: { images, loadingimages } } = this.props

        let proyecto = this.state

        let markupform = (
            !loadingmessage ? (
                <div className='card shadow-none p-3 mb-5 bg-light rounded'>
                    <div className='form nuevo-config' onSubmit={this.guardarproyecto.bind(this)}>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                {
                                    !loadingimages && images.length === 0 ? (
                                        <img src={gallery} alt='anuncio' className='galeria' />
                                    ) : !loadingimages && images.length > 0 ? (
                                        <img src={images[0].imageUrl} alt='anuncio' className='foto' />
                                    ) : (
                                                <div className='seccion-loading'>
                                                    <div className="d-flex justify-content-center">
                                                        <div className='spinner-border' role='status' style={{ display: loadingimages ? 'block' : 'none' }}>
                                                            <span className='sr-only'>Loading...</span>
                                                        </div >
                                                    </div >
                                                </div >
                                            )
                                }
                                < input href='#' hidden='hidden' className='btn' type='file' accept='.gif, .jpg, .jpeg, .png'
                                    id='imageInput' onChange={this.handleImageChange.bind(this)} />
                                <button className='btn imagen' id='imagen' type='button' onClick={this.handleEditPicture.bind(this)}>SELECCIONAR IMAGEN</button>
                            </div>
                            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='form-group primero'>
                                            <label><strong>Indice del dato de la empresa</strong></label>
                                            <select className='form-control' id='indice' onChange={this.handleInputChange.bind(this)}>
                                                <option defaultValue='0'>Seleccionar orden</option>
                                                <option value='01'>Primer proyecto</option>
                                                <option value='02'>Segundo proyecto</option>
                                                <option value='03'>Tercer proyecto</option>
                                                <option value='04'>Cuarto proyecto</option>
                                                <option value='05'>Quinto proyecto</option>
                                                <option value='06'>Sexto proyecto</option>
                                                <option value='07'>Séptimo proyecto</option>
                                                <option value='08'>Octavo proyecto</option>
                                                <option value='09'>Noveno proyecto</option>
                                                <option value='10'>Decimo proyecto</option>
                                                <option value='11'>Decimo primer proyecto</option>
                                                <option value='12'>Decimo segundo proyecto</option>
                                                <option value='13'>Decimo tercer proyecto</option>
                                                <option value='14'>Decimo cuarto proyecto</option>
                                                <option value='15'>Decimo quinto proyecto</option>
                                                <option value='16'>Decimo sexto proyecto</option>
                                                <option value='17'>Decimo séptimo proyecto</option>
                                                <option value='18'>Decimo octavo proyecto</option>
                                                <option value='19'>Decimo noveno proyecto</option>
                                                <option value='20'>Decimo segundo proyecto</option>
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label><strong>Título</strong></label>
                                            <input type='text' className='form-control' id='titulo' aria-describedby='textHelp'
                                                value={proyecto.titulo} onChange={this.handleInputChange.bind(this)}
                                                placeholder='Título del proyecto' />
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='form-group'>
                                            <label><strong>Descripción</strong></label>
                                            <textarea type='text' className='form-control' id='descripcion' aria-describedby='textHelp'
                                                value={proyecto.descripcion} rows='4' onChange={this.handleInputChange.bind(this)}
                                                placeholder='Descripcion del proyecto' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8'>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                                <button className='btn guardar' onClick={this.guardarproyecto.bind(this)}>GUARDAR PROYECTO</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className='seccion-loading'>
                        <div className="d-flex justify-content-center">
                            <div className='spinner-border' role='status' style={{ display: loadingmessage ? 'block' : 'none' }}>
                                <span className='sr-only'>Loading...</span>
                            </div >
                        </div >
                    </div >
                )
        )

        return (
            <div>
                {markupform}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    message: state.message,
    images: state.images,
})

NuevoProyecto.propTypes = {
    message: PropTypes.object.isRequired,
    images: PropTypes.object.isRequired,
    nuevoWebAdminProyecto: PropTypes.func.isRequired,
    nuevaImagenWebAdmin: PropTypes.func.isRequired
}

const mapActionsToProps = {
    nuevoWebAdminProyecto,
    nuevaImagenWebAdmin
}

export default connect(mapStateToProps, mapActionsToProps)(NuevoProyecto)