import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import gallery from '../../images/iconos/gallery.png'
import borrar from '../../images/iconos/borrar.png'
import view from '../../images/iconos/view.png'
import editar from '../../images/iconos/editar.png'

import { connect } from 'react-redux'
import {
    obtenerWebAdminSliders, obtenerWebAdminSlider,
    actualizarWebAdminSlider, borrarWebAdminSlider,
    nuevaImagenTemporal, borrarWebAdminImagenesTemporal,
    borrarWebAdminImagenTemporal,
    agregarBorrarWebAdminImagenTemporal
} from '../../redux/actions/dataActions'

var $ = window.$

class ListaSliders extends React.Component{
    constructor (props){
        super (props)
        this.state = {
            titulo: '',
            subtitulo: '',
            indice: '',
            image: ''
        }
    }

    componentWillMount() {
        this.props.obtenerWebAdminSliders()
    }

    verslider(e) {
        e.preventDefault()

        let idslider = e.target.id

        this.props.obtenerWebAdminSlider(idslider)

        $('#cardverslider').modal('toggle')
    }

    editarslider(e) {
        e.preventDefault()

        let idslider = e.target.id

        this.props.obtenerWebAdminSlider(idslider, true)

        $('#cardeditarslider').modal('toggle')
    }

    borrarslider(e) {
        e.preventDefault()

        let idslider = e.target.id

        this.props.borrarWebAdminSlider(idslider)
    }

    borrarimagen(e) {
        e.preventDefault()

        let id = e.target.id.split('/')[7].split('.')[0]

        this.props.borrarWebAdminImagenTemporal(id)
    }

    actualizar(e){
        e.preventDefault ()

        const {slider: {slider}} = this.props
        const {images: {images}} = this.props

        let idslider = e.target.id

        let data = {
            titulo: this.state.titulo === '' ? slider.titulo : this.state.titulo,
            subtitulo: this.state.subtitulo === '' ? slider.subtitulo : this.state.subtitulo,
            indice: this.state.indice === '' ? slider.indice : this.state.indice,
            image: images
        }

        console.log (data)

        this.props.actualizarWebAdminSlider(idslider, data)

        $('#cardeditarslider').modal('toggle')
    }

    cerrreditarslider(e) {
        e.preventDefault()

        $('#cardeditarslider').modal('toggle')
        this.props.borrarWebAdminImagenesTemporal()
    }

    handleInputChange(e) {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value

        if (id === 'indice') {
            if (value !== '0') {
                this.setState(update(this.state, {
                    indice: { $set: value }
                }))
            }
        } else {
            this.setState(update(this.state, {
                [id]: { $set: value }
            }))
        }
    }

    handleImageChange(e) {
        const image = e.target.files[0]
        const formData = new FormData()
        formData.append('image', image, image.name)

        this.props.agregarBorrarWebAdminImagenTemporal(formData)
    }

    handleEditPicture(e) {
        const fileInput = document.getElementById('imageInput')
        fileInput.click()
    }

    render() {
        const { sliders: { sliders, loadingsliders } } = this.props
        const { slider: { slider, loadingslider } } = this.props
        const { message: { message, loadingmessage } } = this.props
        const { images: { images, loadingimages } } = this.props

        let markupverslider = (
            <div className='card-info-config ver'>
                {
                    !loadingslider && slider && slider.image ? (
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{slider.titulo}</h5>
                            </div>
                            <div className='card-body'>
                                <img src={slider.image[0].imageUrl} alt='imagen-slider' />
                                <div className='row'>
                                    <div className='col-6 col-sm-8 col-md-8 col-lg-8 col-xl-8 izq'>
                                        <h5>{slider.subtitulo}</h5>
                                    </div>
                                    <div className='col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 der'>
                                        <h5><strong>Indice: </strong>{slider.indice}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='seccion-loading'>
                            <div className="d-flex justify-content-center">
                                <div className='spinner-border' role='status' style={{ display: loadingslider ? 'block' : 'none' }}>
                                    <span className='sr-only'>Loading...</span>
                                </div >
                            </div >
                        </div >
                    )
                }
            </div>
        )

        let markupeditarslider = (
            !loadingslider && !loadingmessage ? (
                slider && slider.image ? (
                    <div className='card-info-config slider'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{slider.titulo}</h5>
                            </div>
                            <form onSubmit={this.actualizar.bind (this)}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <div className='principal'>
                                                <img src={gallery} alt='anuncio' />
                                                < input href='#' hidden='hidden' className='btn' type='file' accept='.gif, .jpg, .jpeg, .png'
                                                    id='imageInput' onChange={this.handleImageChange.bind(this)} />
                                                <button className='btn imagen' id='imagen' type='button' onClick={this.handleEditPicture.bind(this)}>CAMBIAR IMAGEN</button>
                                            </div>
                                            {
                                                slider.image.map((datos, index) => {
                                                    return (
                                                        <div className='imagen' key={index}>
                                                            <img key={index} className='img' src={datos.imageUrl} id={datos.id} alt='imagen' />
                                                            <img src={borrar} className='borrar-img' id={datos.imageUrl} onClick={this.borrarimagen.bind(this)} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <div className='form-group'>
                                                <label htmlFor='indice'>Indice del slider</label>
                                                <div className='form-group primero'>
                                                    <select className='form-control' id='indice' onChange={this.handleInputChange.bind(this)}>
                                                        <option defaultValue='0'>{slider.indice}</option>
                                                        <option value='01'>Primer slider</option>
                                                        <option value='02'>Segundo slider</option>
                                                        <option value='03'>Tercer slider</option>
                                                        <option value='04'>Cuarto slider</option>
                                                        <option value='05'>Quinto slider</option>
                                                        <option value='06'>Sexto slider</option>
                                                        <option value='07'>Séptimo slider</option>
                                                        <option value='08'>Octavo slider</option>
                                                        <option value='09'>Noveno slider</option>
                                                        <option value='10'>Decimo slider</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='titulo'>Título</label>
                                                <input type='text' className='form-control' id='titulo' aria-describedby='textHelp'
                                                    value={this.state.titulo} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={slider.titulo !== '' ? slider.titulo : 'Ingrese el título del slider'} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='subtitulo'>Sub título</label>
                                                <input type='text' className='form-control' id='subtitulo' aria-describedby='textHelp'
                                                    value={this.state.subtitulo} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={slider.subtitulo !== '' ? slider.subtitulo : 'Ingrese el subtitulo del slider'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <button className='btn cancelar' onClick={this.cerrreditarslider.bind(this)}>CANCELAR</button>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <button className='btn actualizar' id={slider.id} onClick={this.actualizar.bind(this)}>ACTUALIZAR</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : null
            ) : (
                <div className='seccion-loading'>
                    <div className="d-flex justify-content-center">
                        <div className='spinner-border' role='status' style={{ display: loadingslider || loadingmessage ? 'block' : 'none' }}>
                            <span className='sr-only'>Loading...</span>
                        </div >
                    </div >
                </div >
            )
        )

        let markuplista = (
            !loadingsliders && !loadingmessage && sliders ? (
                sliders.map ((datos, index) => {
                    return (
                        <div key={index} className='row' style={{ backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#f7f7f7' }}>
                            <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1'>
                                <p>{index + 1}</p>
                            </div>
                            <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'>
                                <img src={datos.image[0].imageUrl} alt='imagen' className='imagen-mostrar'/>
                            </div>
                            <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                                <p>{datos.titulo}</p>
                            </div>
                            <div className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-3'>
                                <p>{datos.subtitulo}</p>
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={view} alt='ver' id={datos.id} className='imagen-edit' onClick={this.verslider.bind(this)} />
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={editar} alt='ver' id={datos.id} className='imagen-edit'onClick={this.editarslider.bind(this)} />
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={borrar} alt='ver' id={datos.id} className='imagen-edit'onClick={this.borrarslider.bind(this)} />
                            </div>
                            <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1'/>
                        </div>
                    )
                })
            ): (
                <div className = 'seccion-loading'>
                    <div className = "d-flex justify-content-center">
                            <div className='spinner-border' role='status' style={{ display: loadingsliders || loadingmessage ? 'block' : 'none'}}>
                            <span className='sr-only'>Loading...</span>
                        </div >
                    </div >
                </div >
            )
        )

        return (
            <div className='lista-config sliders'>
                <div className='overflow-auto'>
                    <div className='card-config'>
                        <div className='card shadow-sm p-3 mb-5 bg-light rounded'>
                            <div className='titulo' style={{display: sliders && sliders.length > 0 ? 'block' : 'none'}}>
                                <div className='row'>
                                    <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'>
                                        <p><strong>Imágen</strong></p>
                                    </div>
                                    <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                                        <p><strong>Título</strong></p>
                                    </div>
                                    <div className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-3'>
                                        <p><strong>Subtitulo</strong></p>
                                    </div>
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                                </div>
                            </div>
                            <div className='lista'>
                                {markuplista}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id='cardverslider'>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className='overflow-auto'>
                                {markupverslider}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id='cardeditarslider'>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className='overflow-auto'>
                                {markupeditarslider}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sliders: state.sliders,
    slider: state.slider,
    message: state.message,
    images: state.images
})

ListaSliders.propTypes = {
    sliders: PropTypes.object.isRequired,
    slider: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    images: PropTypes.object.isRequired,
    obtenerWebAdminSliders: PropTypes.func.isRequired,
    obtenerWebAdminSlider: PropTypes.func.isRequired,
    actualizarWebAdminSlider: PropTypes.func.isRequired,
    borrarWebAdminSlider: PropTypes.func.isRequired,
    nuevaImagenTemporal: PropTypes.func.isRequired,
    borrarWebAdminImagenesTemporal: PropTypes.func.isRequired,
    borrarWebAdminImagenTemporal: PropTypes.func.isRequired,
    agregarBorrarWebAdminImagenTemporal: PropTypes.func.isRequired
}

const mapActionsToProps = {
    obtenerWebAdminSliders,
    obtenerWebAdminSlider,
    actualizarWebAdminSlider,
    borrarWebAdminSlider,
    nuevaImagenTemporal,
    borrarWebAdminImagenesTemporal,
    borrarWebAdminImagenTemporal,
    agregarBorrarWebAdminImagenTemporal
}

export default connect(mapStateToProps, mapActionsToProps)(ListaSliders)