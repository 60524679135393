import {
    LOADING_DATA_ADMIN,
    SET_DATA_ADMIN,
    SET_ERROR_ADMIN,

    LOADING_DATA_MESSAGE,
    SET_DATA_MESSAGE,
    SET_ERROR_MESSAGE,

    LOADING_DATA_IMAGES,
    SET_DATA_IMAGES,
    SET_ERROR_IMAGES,

    LOADING_DATA_IMAGE,
    SET_DATA_IMAGE,
    SET_ERROR_IMAGE,

    LOADING_DATA_SLIDERS,
    SET_DATA_SLIDERS,
    SET_ERROR_SLIDERS,

    LOADING_DATA_SLIDER,
    SET_DATA_SLIDER,
    SET_ERROR_SLIDER,

    LOADING_DATA_PROYECTOS,
    SET_DATA_PROYECTOS,
    SET_ERROR_PROYECTOS,

    LOADING_DATA_PROYECTO,
    SET_DATA_PROYECTO,
    SET_ERROR_PROYECTO,

    LOADING_DATA_SERVICIOS,
    SET_DATA_SERVICIOS,
    SET_ERROR_SERVICIOS,

    LOADING_DATA_SERVICIO,
    SET_DATA_SERVICIO,
    SET_ERROR_SERVICIO

} from '../types'

import {browserHistory} from 'react-router'

import axios from 'axios'
var $ = window.$

export const nuevoWebDatosAdmin = (datos) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/datos/admin', datos)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
            browserHistory.push ('/contacto')
        }).catch(err => {
            dispatch({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const actualizarWebAdminDatosAdmin = (data, idadmin) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/datos/admin/' + idadmin, data)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)

            $('#modaleditaradmin').modal('toggle')
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const obtenerWebAdminDatosAdmin = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_ADMIN })
    axios.get('/datos/admin')
        .then((res) => {
            dispatch({
                type: SET_DATA_ADMIN,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_ADMIN,
                payload: err.data
            })
        })
}

export const nuevaImagenWebAdmin = (formData) => (dispatch) => {
    dispatch({ type: LOADING_DATA_IMAGES })
    axios.post('/imagen/temporal', formData)
        .then((res) => {
            dispatch({
                type: SET_DATA_IMAGES,
                payload: res.data
            })
            console.log(res.data)
        }).catch(err =>
            dispatch({
                type: SET_ERROR_IMAGES,
                payload: err.data
            })
        )
}

export const nuevaImagenTemporal = (formData) => (dispatch) => {
    dispatch({ type: LOADING_DATA_IMAGES })
    axios.post('/imagen/temporal', formData)
        .then((res) => {
            dispatch({
                type: SET_DATA_IMAGES,
                payload: res.data
            })
            console.log(res.data)
        }).catch(err => {
            dispatch({
                type: SET_ERROR_IMAGES,
                payload: err.data
            })
        })
}

export const borrarWebAdminImagenTemporal = (imagen) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.delete('/imagen/temporal/' + imagen)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            dispatch(obtenerWebImagenesTemporales ())
            console.log(res.data)
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const obtenerWebImagenesTemporales = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_IMAGES })
    axios.get('/imagenes/temporal')
        .then((res) => {
            dispatch({
                type: SET_DATA_IMAGES,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_IMAGES,
                payload: err.data
            })
        })
    
}

export const borrarWebAdminImagenesTemporal = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.delete('/imagenes/temporal')
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const agregarBorrarWebAdminImagenTemporal = (formData) => (dispatch) => {
    dispatch({ type: LOADING_DATA_IMAGES })
    axios.post('/borrar/agregar/imagen/temporal', formData)
        .then((res) => {
            dispatch({
                type: SET_DATA_IMAGES,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_IMAGES,
                payload: err.data
            })
        })
}

export const nuevoWebAdminServicio = (servicio) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/servicio', servicio)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
            dispatch(borrarWebAdminImagenesTemporal())
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const obtenerWebAdminServicios = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_SERVICIOS })
    axios.get('/servicios')
        .then((res) => {
            dispatch({
                type: SET_DATA_SERVICIOS,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_SERVICIOS,
                payload: err.data
            })
        })
}

export const obtenerWebAdminServicio = (idservicio) => (dispatch) => {
    dispatch({ type: LOADING_DATA_SERVICIO })
    axios.get('/servicio/' + idservicio)
        .then((res) => {
            dispatch({
                type: SET_DATA_SERVICIO,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_SERVICIO,
                payload: err.data
            })
        })

}

export const actualizarWebAdminServicio = (idservicio, servicio) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/servicio/' + idservicio, servicio)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const borrarWebAdminServicio = (idservicio) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.delete('/servicio/' + idservicio)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const nuevoWebAdminProyecto = (proyecto) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/proyecto', proyecto)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
            dispatch (borrarWebAdminImagenesTemporal())
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const obtenerWebAdminProyectos = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_PROYECTOS })
    axios.get('/proyectos')
        .then((res) => {
            dispatch({
                type: SET_DATA_PROYECTOS,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_PROYECTOS,
                payload: err.data
            })
        })
}

export const obtenerWebAdminProyecto = (idproyecto, imagen) => (dispatch) => {
    dispatch({ type: LOADING_DATA_PROYECTO })
    axios.get('/proyecto/' + idproyecto)
        .then((res) => {
            dispatch({
                type: SET_DATA_PROYECTO,
                payload: res.data
            })
            console.log(res.data)
            if (imagen) {
                dispatch(guardarImagenesTemporal(res.data.proyecto.image))
            }
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_PROYECTO,
                payload: err.data
            })
        })

}

export const actualizarWebAdminProyecto = (idproyecto, proyecto) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/proyecto/' + idproyecto, proyecto)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const borrarWebAdminProyecto = (idproyecto) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.delete('/proyecto/' + idproyecto)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })

}

export const nuevoWebAdminSlider = (slider) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/slider', slider)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            dispatch (borrarWebAdminImagenesTemporal())
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const obtenerWebAdminSliders = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_SLIDERS })
    axios.get('/sliders')
        .then((res) => {
            dispatch({
                type: SET_DATA_SLIDERS,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_SLIDERS,
                payload: err.data
            })
        })
}

export const obtenerWebAdminSlider = (idslider, imagen) => (dispatch) => {
    dispatch({ type: LOADING_DATA_SLIDER })
    axios.get('/slider/' + idslider)
        .then((res) => {
            dispatch({
                type: SET_DATA_SLIDER,
                payload: res.data
            })
            console.log(res.data)
            console.log(res.data.slider.image)
            if (imagen){
                dispatch(guardarImagenesTemporal(res.data.slider.image))
            }
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_SLIDER,
                payload: err.data
            })
        })
}

export const guardarImagenesTemporal = (imagenes) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/imagenes/temporal', imagenes)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })

}

export const actualizarWebAdminSlider = (idslider, slider) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.post('/slider/' + idslider, slider)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })
}

export const borrarWebAdminSlider = (idslider) => (dispatch) => {
    dispatch({ type: LOADING_DATA_MESSAGE })
    axios.delete('/slider/' + idslider)
        .then((res) => {
            dispatch({
                type: SET_DATA_MESSAGE,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch ({
                type: SET_ERROR_MESSAGE,
                payload: err.data
            })
        })

}