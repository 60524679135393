import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import borrar from '../../images/iconos/borrar.png'
import view from '../../images/iconos/view.png'
import editar from '../../images/iconos/editar.png'
import gallery from '../../images/iconos/gallery.png'

import { connect } from 'react-redux'
import {
    obtenerWebAdminServicios, obtenerWebAdminServicio,
    actualizarWebAdminServicio, borrarWebAdminServicio,
    nuevaImagenWebAdmin,
    borrarWebAdminImagenTemporal
} from '../../redux/actions/dataActions'

var $ = window.$

class ListaServicios extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            titulo: '',
            descripcion: '',
            indice: '',
            imagen: ''
        }
    }

    componentWillMount() {
        this.props.obtenerWebAdminServicios()
    }

    verservicio(e) {
        e.preventDefault()

        let idservicio = e.target.id

        this.props.obtenerWebAdminServicio(idservicio)

        $('#cardverservicio').modal('toggle')
    }

    editarproyoecto(e) {
        e.preventDefault()

        let idservicio = e.target.id

        this.props.obtenerWebAdminServicio(idservicio, true)

        $('#cardeditarservicio').modal('toggle')
    }

    borrarservicio(e) {
        e.preventDefault()

        let idservicio = e.target.id

        this.props.borrarWebAdminServicio(idservicio)
    }

    actualizar(e) {
        e.preventDefault()

        const { servicio: { servicio } } = this.props
        const { image: { image } } = this.props

        let idservicio = e.target.id

        let data = {
            titulo: this.state.titulo === '' ? servicio.titulo : this.state.titulo,
            descripcion: this.state.descripcion === '' ? servicio.descripcion : this.state.descripcion,
            indice: this.state.indice === '' ? servicio.indice : this.state.indice,
            imagen: image === '' ? servicio.imagen : image
        }

        console.log(data)

        this.props.actualizarWebAdminServicio(idservicio, data)

        $('#cardeditarservicio').modal('toggle')
    }

    cancelar(e) {
        e.preventDefault()

        $('#cardeditarservicio').modal('toggle')
    }

    handleInputChange(e) {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value

        if (id === 'indice') {
            if (value !== '0') {
                this.setState(update(this.state, {
                    indice: { $set: value }
                }))
            }
        } else {
            this.setState(update(this.state, {
                [id]: { $set: value }
            }))
        }
    }

    borrarimagen(e) {
        e.preventDefault()

        let id = e.target.id.split('/')[7].split('.')[0]

        this.props.borrarWebAdminImagenTemporal(id)
    }

    handleImageChange(e) {
        const image = e.target.files[0]
        const formData = new FormData()
        formData.append('image', image, image.name)

        this.props.nuevaImagenWebAdmin(formData)
    }

    handleEditPicture(e) {
        const fileInput = document.getElementById('imageInput')
        fileInput.click()
    }

    render() {
        const { servicios: { servicios, loadingservicios } } = this.props
        const { servicio: { servicio, loadingservicio } } = this.props
        const { message: { message, loadingmessage } } = this.props
        const { image: { image, loadingimage } } = this.props

        let markupverservicio = (
            <div className='card-info-config ver'>
                {
                    !loadingservicio && servicio && servicio.image ? (
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{servicio.titulo}</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='card-body'>
                                <img src={servicio.image[0].imageUrl} alt='imagen-slider' />
                                <div className='row'>
                                    <div className='col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 der'>
                                        <h4><strong>{servicio.titulo}</strong></h4>
                                        <h5><strong>Indice: </strong>{servicio.indice}</h5>
                                    </div>
                                    <div className='col-6 col-sm-8 col-md-8 col-lg-8 col-xl-8 izq'>
                                        <h5>{servicio.descripcion}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='seccion-loading'>
                            <div className="d-flex justify-content-center">
                                <div className='spinner-border' role='status' style={{ display: loadingservicio ? 'block' : 'none' }}>
                                    <span className='sr-only'>Loading...</span>
                                </div >
                            </div >
                        </div >
                    )
                }
            </div>
        )

        let markupeditarservicio = (
            !loadingservicio && !loadingmessage ? (
                servicio && servicio.image ? (
                    <div className='card-info-config proyecto'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{servicio.titulo}</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <form onSubmit={this.actualizar.bind(this)}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <div className='principal'>
                                                <img src={gallery} alt='anuncio' />
                                                < input href='#' hidden='hidden' className='btn' type='file' accept='.gif, .jpg, .jpeg, .png'
                                                    id='imageInput' onChange={this.handleImageChange.bind(this)} />
                                                <button className='btn imagen' id='imagen' type='button' onClick={this.handleEditPicture.bind(this)}>CAMBIAR IMAGEN</button>
                                            </div>
                                            {
                                                servicio.image.map((datos, index) => {
                                                    return (
                                                        <div className='imagen' key={index}>
                                                            <img key={index} className='img' src={datos.imageUrl} id={datos.id} alt='imagen' />
                                                            <img src={borrar} className='borrar-img' id={datos.imageUrl} onClick={this.borrarimagen.bind(this)} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 primero'>
                                                    <div className='form-group'>
                                                        <label htmlFor='indice'>Indice del slider</label>
                                                        <div className='form-group primero'>
                                                            <select className='form-control' id='indice' onChange={this.handleInputChange.bind(this)}>
                                                                <option defaultValue='0'>{servicio.indice}</option>
                                                                <option value='01'>Primer servicio</option>
                                                                <option value='02'>Segundo servicio</option>
                                                                <option value='03'>Tercer servicio</option>
                                                                <option value='04'>Cuarto servicio</option>
                                                                <option value='05'>Quinto servicio</option>
                                                                <option value='06'>Sexto servicio</option>
                                                                <option value='07'>Séptimo servicio</option>
                                                                <option value='08'>Octavo servicio</option>
                                                                <option value='09'>Noveno servicio</option>
                                                                <option value='10'>Decimo servicio</option>
                                                                <option value='11'>Decimo primer servicio</option>
                                                                <option value='12'>Decimo segundo servicio</option>
                                                                <option value='13'>Decimo tercer servicio</option>
                                                                <option value='14'>Decimo cuarto servicio</option>
                                                                <option value='15'>Decimo quinto servicio</option>
                                                                <option value='16'>Decimo sexto servicio</option>
                                                                <option value='17'>Decimo séptimo servicio</option>
                                                                <option value='18'>Decimo octavo servicio</option>
                                                                <option value='19'>Decimo noveno servicio</option>
                                                                <option value='20'>Duo decimo servicio</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='titulo'>Título</label>
                                                        <input type='text' className='form-control' id='titulo' aria-describedby='textHelp'
                                                            value={this.state.titulo} onChange={this.handleInputChange.bind(this)}
                                                            placeholder={servicio.titulo} />
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 segundo'>
                                                    <div className='form-group'>
                                                        <label htmlFor='descripcion'>Descripción</label>
                                                        <textarea type='text' rows='4' className='form-control' id='descripcion' aria-describedby='textHelp'
                                                            value={this.state.descripcion} onChange={this.handleInputChange.bind(this)}
                                                            placeholder={servicio.descripcion} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <button className='btn cancelar' onClick={this.cancelar.bind(this)}>CANCELAR</button>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <button className='btn actualizar' id={servicio.id} onClick={this.actualizar.bind(this)}>ACTUALIZAR</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : null
            ) : (
                <div className='seccion-loading'>
                    <div className="d-flex justify-content-center">
                        <div className='spinner-border' role='status' style={{ display: loadingservicio || loadingmessage ? 'block' : 'none' }}>
                            <span className='sr-only'>Loading...</span>
                        </div >
                    </div >
                </div >
            )
        )

        let markuplista = (
            !loadingservicios && !loadingmessage && servicios ? (
                servicios.map((datos, index) => {
                    return (
                        <div key={index} className='row' style={{ backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#f7f7f7' }}>
                            <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1'>
                                <p>{index + 1}</p>
                            </div>
                            <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'>
                                <img src={datos.image[0].imageUrl} alt='imagen' className='imagen-mostrar' />
                            </div>
                            <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                                <p>{datos.titulo}</p>
                            </div>
                            <div className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-3'>
                                <p>{datos.descripcion}</p>
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={view} alt='ver' id={datos.id} className='imagen-edit' onClick={this.verservicio.bind(this)} />
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={editar} alt='ver' id={datos.id} className='imagen-edit' onClick={this.editarproyoecto.bind(this)} />
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={borrar} alt='ver' id={datos.id} className='imagen-edit' onClick={this.borrarservicio.bind(this)} />
                            </div>
                            <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                        </div>
                    )
                })
            ) : (
                <div className='seccion-loading'>
                    <div className="d-flex justify-content-center">
                        <div className='spinner-border' role='status' style={{ display: loadingservicios || loadingmessage ? 'block' : 'none' }}>
                            <span className='sr-only'>Loading...</span>
                        </div >
                    </div >
                </div >
            )
        )

        return (
            <div className='lista-config datos-empresa'>
                <div className='overflow-auto'>
                    <div className='card-config'>
                        <div className='card shadow-sm p-3 mb-5 bg-light rounded'>
                            <div className='titulo' style={{ display: servicios && servicios.length > 0 ? 'block' : 'none' }}>
                                <div className='row'>
                                    <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'>
                                        <p><strong>Imágen</strong></p>
                                    </div>
                                    <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                                        <p><strong>Título</strong></p>
                                    </div>
                                    <div className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-3'>
                                        <p><strong>Descripción</strong></p>
                                    </div>
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                                </div>
                            </div>
                            <div className='lista'>
                                {markuplista}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id='cardverservicio'>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className='overflow-auto'>
                                {markupverservicio}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id='cardeditarservicio'>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className='overflow-auto'>
                                {markupeditarservicio}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    servicios: state.servicios,
    servicio: state.servicio,
    message: state.message,
    image: state.image
})

ListaServicios.propTypes = {
    servicios: PropTypes.object.isRequired,
    servicio: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    obtenerWebAdminServicios: PropTypes.func.isRequired,
    obtenerWebAdminServicio: PropTypes.func.isRequired,
    actualizarWebAdminServicio: PropTypes.func.isRequired,
    borrarWebAdminServicio: PropTypes.func.isRequired,
    borrarWebAdminImagenTemporal: PropTypes.func.isRequired,
    nuevaImagenWebAdmin: PropTypes.func.isRequired
}

const mapActionsToProps = {
    obtenerWebAdminServicios,
    obtenerWebAdminServicio,
    actualizarWebAdminServicio,
    borrarWebAdminServicio,
    nuevaImagenWebAdmin,
    borrarWebAdminImagenTemporal
}

export default connect(mapStateToProps, mapActionsToProps)(ListaServicios)