import {
    LOADING_DATA_SLIDERS,
    SET_DATA_SLIDERS,
    SET_ERROR_SLIDERS
} from '../types'

const initialState = {
    loadingsliders: false,
    sliders: [],
    message: '',
    error: ''
}


export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA_SLIDERS:
            return {
                ...state,
                loadingsliders: true
            }
        case SET_DATA_SLIDERS:
            return {
                loadingsliders: false,
                ...action.payload
            }
        case SET_ERROR_SLIDERS:
            return {
                loadingproyectos: false,
                ...action.payload
            }
        default: return state
    }
}