import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import { browserHistory } from 'react-router'

import { connect } from 'react-redux'
import {
    nuevoWebDatosAdmin
} from '../../redux/actions/dataActions'

var $ = window.$

class NuevoDatoAdmin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empresa: '',
            fijo: '',
            celular: '',
            correo: '',
            direccion: '',
            facebook: '',
            instagram: '',
            linkedin: '',
            twitter: ''
        }
    }

    guardardatosadmin(e) {
        e.preventDefault()

        let datos = this.state

        if (datos.empresa === '' || datos.telefono === '' || datos.correo === '' || 
            datos.direccion === '') {
            alert('Debe completar todos los campos')
        } else {
            let data = {
                empresa: datos.empresa,
                fijo: datos.fijo,
                celular: datos.celular,
                correo: datos.correo,
                direccion: datos.direccion,
                facebook: datos.facebook,
                instagram: datos.instagram,
                linkedin: datos.linkedin,
                twitter: datos.twitter
            }

            console.log(data)
            this.props.nuevoWebDatosAdmin(data)

            this.setState(update(this.state, {
                empresa: { $set: '' },
                fijo: { $set: '' },
                celular: { $set: '' },
                correo: { $set: '' },
                direccion: { $set: '' },
                facebook: { $set: '' },
                instagram: { $set: '' },
                linkedin: { $set: '' },
                twitter: { $set: '' }
            }))

            window.scrollTo(0, 0)
        }
    }

    handleInputChange(e) {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value

        this.setState(update(this.state, {
            [id]: { $set: value }
        }))
    }

    render() {
        const { message: { message, loadingmessage } } = this.props

        let datos = this.state

        let markupform = (
            !loadingmessage ? (
                <div className='card shadow-none p-3 mb-5 bg-light rounded'>
                    <div className='form nuevo-config' onSubmit={this.guardardatosadmin.bind(this)}>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                <div className='form-group'>
                                    <label><strong>Nombre de la empresa a mostrar</strong></label>
                                    <input type='text' className='form-control' id='empresa' aria-describedby='textHelp'
                                        value={datos.empresa} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Nombre de la empresa' />

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <div className='form-group'>
                                    <label><strong>Teléfono celular</strong></label>
                                    <input type='number' className='form-control' id='celular' aria-describedby='numberHelp'
                                        value={datos.celular} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Teléfono celular' />

                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <div className='form-group'>
                                    <label><strong>Teléfono fijo</strong></label>
                                    <input type='number' className='form-control' id='fijo' aria-describedby='numberHelp'
                                        value={datos.fijo} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Teléfono fijo' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                <div className='form-group'>
                                    <label><strong>Correo de contacto</strong></label>
                                    <input type='text' className='form-control' id='correo' aria-describedby='textHelp'
                                        value={datos.correo} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Correo de contacto' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                <div className='form-group'>
                                    <label><strong>Ubicación de la emrpesa</strong></label>
                                    <input type='text' className='form-control' id='direccion' aria-describedby='textHelp'
                                        value={datos.direccion} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Ubicación de la emrpesa' />

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <div className='form-group'>
                                    <label><strong>Link de su facebook</strong></label>
                                    <input type='text' className='form-control' id='facebook' aria-describedby='textHelp'
                                        value={datos.facebook} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Link de su facebook' />

                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <div className='form-group'>
                                    <label><strong>Link de su instagram</strong></label>
                                    <input type='text' className='form-control' id='instagram' aria-describedby='textHelp'
                                        value={datos.instagram} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Link de su instagram' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <div className='form-group'>
                                    <label><strong>Link de su twitter</strong></label>
                                    <input type='text' className='form-control' id='twitter' aria-describedby='textHelp'
                                        value={datos.twitter} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Link de su twitter' />

                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <div className='form-group'>
                                    <label><strong>Link de su linkedin</strong></label>
                                    <input type='text' className='form-control' id='linkedin' aria-describedby='textHelp'
                                        value={datos.linkedin} onChange={this.handleInputChange.bind(this)}
                                        placeholder='Link de su linkedin' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8'>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                                <button className='btn guardar' onClick={this.guardardatosadmin.bind(this)}>GUARDAR SUS DATOS</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className='seccion-loading'>
                        <div className="d-flex justify-content-center">
                            <div className='spinner-border' role='status' style={{ display: loadingmessage ? 'block' : 'none' }}>
                                <span className='sr-only'>Loading...</span>
                            </div >
                        </div >
                    </div >
                )
        )

        return (
            <div>
                {markupform}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    message: state.message,
    image: state.image,
})

NuevoDatoAdmin.propTypes = {
    message: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    nuevoWebDatosAdmin: PropTypes.func.isRequired
}

const mapActionsToProps = {
    nuevoWebDatosAdmin
}

export default connect(mapStateToProps, mapActionsToProps)(NuevoDatoAdmin)