export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'

export const LOADING_DATA_USER = 'LOADING_DATA_USER'
export const SET_DATA_USER = 'SET_DATA_USER'
export const SET_ERROR_ADMIN = 'SET_ERROR_ADMIN'

export const LOADING_DATA_ADMIN = 'LOADING_DATA_ADMIN'
export const SET_DATA_ADMIN = 'SET_DATA_ADMIN'
export const SET_ERROR_USER = 'set_error_user'

export const LOADING_DATA_MESSAGE = 'LOADING_DATA_MESSAGE'
export const SET_DATA_MESSAGE = 'SET_DATA_MESSAGE'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'

export const LOADING_DATA_IMAGES = 'LOADING_DATA_IMAGES'
export const SET_DATA_IMAGES = 'SET_DATA_IMAGES'
export const SET_ERROR_IMAGES = 'SET_ERROR_IMAGES'

export const LOADING_DATA_IMAGE = 'LOADING_DATA_IMAGE'
export const SET_DATA_IMAGE = 'SET_DATA_IMAGE'
export const SET_ERROR_IMAGE = 'SET_ERROR_IMAGE'

export const LOADING_DATA_PROYECTOS = 'LOADING_DATA_PROYECTOS'
export const SET_DATA_PROYECTOS = 'SET_DATA_PROYECTOS'
export const SET_ERROR_PROYECTOS = 'SET_ERROR_PROYECTOS'

export const LOADING_DATA_PROYECTO = 'LOADING_DATA_PROYECTO'
export const SET_DATA_PROYECTO = 'SET_DATA_PROYECTO'
export const SET_ERROR_PROYECTO = 'SET_ERROR_PROYECTO'

export const LOADING_DATA_SERVICIOS = 'LOADING_DATA_SERVICIOS'
export const SET_DATA_SERVICIOS = 'SET_DATA_SERVICIOS'
export const SET_ERROR_SERVICIOS = 'SET_ERROR_SERVICIOS'

export const LOADING_DATA_SERVICIO = 'LOADING_DATA_SERVICIO'
export const SET_DATA_SERVICIO = 'SET_DATA_SERVICIO'
export const SET_ERROR_SERVICIO = 'SET_ERROR_SERVICIO'

export const LOADING_DATA_SLIDERS = 'LOADING_DATA_SLIDERS'
export const SET_DATA_SLIDERS = 'SET_DATA_SLIDERS'
export const SET_ERROR_SLIDERS = 'SET_ERROR_SLIDERS'

export const LOADING_DATA_SLIDER = 'LOADING_DATA_SLIDER'
export const SET_DATA_SLIDER = 'SET_DATA_SLIDER'
export const SET_ERROR_SLIDER = 'SET_ERROR_SLIDER'