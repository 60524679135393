import React from 'react'
import update from 'immutability-helper'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { loginUser } from '../redux/actions/userActions'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            correo: '',
            contrasenia: ''
        }
    }

    login(e) {
        e.preventDefault()

        let data = {
            'correo': this.state.correo,
            'contrasenia': this.state.contrasenia
        }

        this.props.loginUser(data)
    }

    handleInput(e) {
        let id = e.target.id
        let value = e.target.value

        this.setState(update(this.state, {
            [id]: { $set: value }
        }))
    }

    render() {
        const { user: { loading } } = this.props

        let markuploading = (
            !loading ? (
                <div className='card shadow-sm p-3 mb-5 rounded'>
                    <div className='card-header'>
                        <h4>INICIAR SESIÓN</h4>
                        <p>¡Iniciar sesión en su cuenta!</p>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={this.login.bind(this)}>
                            <div className='form-group'>
                                <label htmlFor='correo'>Correo electrónico</label>
                                <input type='email' className='form-control' aria-describedby='emailHelp'
                                    id='correo' placeholder='Correo electrónico' onChange={this.handleInput.bind(this)}
                                    value={this.state.correo} />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='contrasenia'>Contraseña</label>
                                <input type='password' className='form-control' aria-describedby='passwordHelp'
                                    id='contrasenia' placeholder='xxxxxxxxxx' onChange={this.handleInput.bind(this)}
                                    value={this.state.contrasenia} />
                            </div>
                        </form>
                    </div>
                    <div className='card-footer'>
                        <div className='row'>
                            <div className='co-12 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <p>¿Olvidaste tu contraseña?</p>
                            </div>
                            <div className='co-12 col-sm-4 col-md-4 -col-lg-4 col-xl-4'>
                                <button type='submit' className='btn' onClick={this.login.bind(this)}>INGRESAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className='seccion-loading'>
                        <div className="d-flex justify-content-center">
                            <div className='spinner-border' style='width: 5rem; height: 5rem;' role='status' style={{ display: loading ? 'block' : 'none' }}>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                        <h4>Iniciando sesión...</h4>
                    </div>
                )
        )

        return (
            <div className='login'>
                {markuploading}
            </div>
        )
    }
}

Login.propTypes = {
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

const mapActionsToProps = {
    loginUser
}

export default connect(mapStateToProps, mapActionsToProps)(Login)