import {
    LOADING_DATA_SERVICIO,
    SET_DATA_SERVICIO,
    SET_ERROR_SERVICIO
} from '../types'

const initialState = {
    loadingservicio: false,
    servicio: {},
    message: '',
    error: ''
}


export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA_SERVICIO:
            return {
                ...state,
                loadingservicio: true
            }
        case SET_DATA_SERVICIO:
            return {
                loadingservicio: false,
                ...action.payload
            }
        case SET_ERROR_SERVICIO:
            return {
                loadingproyectos: false,
                ...action.payload
            }
        default: return state
    }
}