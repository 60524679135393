import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import adminReducer from './reducers/adminReducer'
import imageReducer from './reducers/imageReducer'
import imagesReducer from './reducers/imagesReducer'
import userReducer from './reducers/userReducer'
import slidersReducer from './reducers/slidersReducer'
import sliderReducer from './reducers/sliderReducer'
import messageReducer from './reducers/messageReducer'
import proyectosReducer from './reducers/proyectosReducer'
import proyectoReducer from './reducers/proyectoReducer'
import serviciosReducer from './reducers/serviciosReducer'
import servicioReducer from './reducers/servicioReducer'

const initialState = {}

const middleware = [
    thunk
]

const reducers = combineReducers({
    user: userReducer,
    admin: adminReducer,
    image: imageReducer,
    images: imagesReducer,
    slider: sliderReducer,
    sliders: slidersReducer,
    message: messageReducer,
    proyecto: proyectoReducer,
    proyectos: proyectosReducer,
    servicio: servicioReducer,
    servicios: serviciosReducer
})

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

export default store