import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div className='board-developer'>
                <p>Copyright @ Developer Ideas 2021</p>
            </div>
        )
    }
}

export default Footer