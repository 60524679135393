import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import borrar from '../../images/iconos/borrar.png'
import view from '../../images/iconos/view.png'
import editar from '../../images/iconos/editar.png'
import gallery from '../../images/iconos/gallery.png'

import { connect } from 'react-redux'
import {
    obtenerWebAdminProyectos, obtenerWebAdminProyecto,
    actualizarWebAdminProyecto, borrarWebAdminProyecto,
    nuevaImagenWebAdmin,
    borrarWebAdminImagenTemporal
} from '../../redux/actions/dataActions'

var $ = window.$

class ListaProyectos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            titulo: '',
            descripcion: '',
            indice: '',
            imagen: ''
        }
    }

    componentWillMount() {
        this.props.obtenerWebAdminProyectos()
    }

    verproyecto(e) {
        e.preventDefault()

        let idproyecto = e.target.id

        this.props.obtenerWebAdminProyecto(idproyecto)

        $('#cardverproyecto').modal('toggle')
    }

    editarproyoecto(e) {
        e.preventDefault()

        let idproyecto = e.target.id

        this.props.obtenerWebAdminProyecto(idproyecto, true)

        $('#cardeditarproyecto').modal('toggle')
    }

    borrarproyecto(e) {
        e.preventDefault()

        let idproyecto = e.target.id

        this.props.borrarWebAdminProyecto(idproyecto)
    }

    actualizar(e) {
        e.preventDefault()

        const { proyecto: { proyecto } } = this.props
        const { image: { image } } = this.props

        let idproyecto = e.target.id

        let data = {
            titulo: this.state.titulo === '' ? proyecto.titulo : this.state.titulo,
            descripcion: this.state.descripcion === '' ? proyecto.descripcion : this.state.descripcion,
            indice: this.state.indice === '' ? proyecto.indice : this.state.indice,
            imagen: image === '' ? proyecto.imagen : image
        }

        console.log(data)

        this.props.actualizarWebAdminProyecto(idproyecto, data)

        $('#cardeditarproyecto').modal('toggle')
    }

    cancelar(e) {
        e.preventDefault()

        $('#cardeditarproyecto').modal('toggle')
    }

    handleInputChange(e) {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value

        if (id === 'indice') {
            if (value !== '0') {
                this.setState(update(this.state, {
                    indice: { $set: value }
                }))
            }
        } else {
            this.setState(update(this.state, {
                [id]: { $set: value }
            }))
        }
    }

    borrarimagen(e) {
        e.preventDefault()

        let id = e.target.id.split('/')[7].split('.')[0]

        this.props.borrarWebAdminImagenTemporal(id)
    }

    handleImageChange(e) {
        const image = e.target.files[0]
        const formData = new FormData()
        formData.append('image', image, image.name)

        this.props.nuevaImagenWebAdmin(formData)
    }

    handleEditPicture(e) {
        const fileInput = document.getElementById('imageInput')
        fileInput.click()
    }

    render() {
        const { proyectos: { proyectos, loadingproyectos } } = this.props
        const { proyecto: { proyecto, loadingproyecto } } = this.props
        const { message: { message, loadingmessage } } = this.props
        const { image: { image, loadingimage } } = this.props

        let markupverproyecto = (
            <div className='card-info-config ver'>
                {
                    !loadingproyecto && proyecto && proyecto.image ? (
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{proyecto.titulo}</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='card-body'>
                                <img src={proyecto.image[0].imageUrl} alt='imagen-slider' />
                                <div className='row'>
                                    <div className='col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 der'>
                                        <h4><strong>{proyecto.titulo}</strong></h4>
                                        <h5><strong>Indice: </strong>{proyecto.indice}</h5>
                                    </div>
                                    <div className='col-6 col-sm-8 col-md-8 col-lg-8 col-xl-8 izq'>
                                        <h5>{proyecto.descripcion}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <div className='seccion-loading'>
                                <div className="d-flex justify-content-center">
                                    <div className='spinner-border' role='status' style={{ display: loadingproyecto ? 'block' : 'none' }}>
                                        <span className='sr-only'>Loading...</span>
                                    </div >
                                </div >
                            </div >
                        )
                }
            </div>
        )

        let markupeditarproyecto = (
            !loadingproyecto && !loadingmessage ? (
                proyecto && proyecto.image ? (
                    <div className='card-info-config proyecto'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{proyecto.titulo}</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <form onSubmit={this.actualizar.bind(this)}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <div className='principal'>
                                                <img src={gallery} alt='anuncio' />
                                                < input href='#' hidden='hidden' className='btn' type='file' accept='.gif, .jpg, .jpeg, .png'
                                                    id='imageInput' onChange={this.handleImageChange.bind(this)} />
                                                <button className='btn imagen' id='imagen' type='button' onClick={this.handleEditPicture.bind(this)}>CAMBIAR IMAGEN</button>
                                            </div>
                                            {
                                                proyecto.image.map((datos, index) => {
                                                    return (
                                                        <div className='imagen' key={index}>
                                                            <img key={index} className='img' src={datos.imageUrl} id={datos.id} alt='imagen' />
                                                            <img src={borrar} className='borrar-img' id={datos.imageUrl} onClick={this.borrarimagen.bind(this)} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <div className='row'>
                                                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 primero'>
                                                    <div className='form-group'>
                                                        <label htmlFor='indice'>Indice del slider</label>
                                                        <div className='form-group primero'>
                                                            <select className='form-control' id='indice' onChange={this.handleInputChange.bind(this)}>
                                                                <option defaultValue='0'>{proyecto.indice}</option>
                                                                <option value='01'>Primer proyecto</option>
                                                                <option value='02'>Segundo proyecto</option>
                                                                <option value='03'>Tercer proyecto</option>
                                                                <option value='04'>Cuarto proyecto</option>
                                                                <option value='05'>Quinto proyecto</option>
                                                                <option value='06'>Sexto proyecto</option>
                                                                <option value='07'>Séptimo proyecto</option>
                                                                <option value='08'>Octavo proyecto</option>
                                                                <option value='09'>Noveno proyecto</option>
                                                                <option value='10'>Decimo proyecto</option>
                                                                <option value='11'>Decimo primer proyecto</option>
                                                                <option value='12'>Decimo segundo proyecto</option>
                                                                <option value='13'>Decimo tercer proyecto</option>
                                                                <option value='14'>Decimo cuarto proyecto</option>
                                                                <option value='15'>Decimo quinto proyecto</option>
                                                                <option value='16'>Decimo sexto proyecto</option>
                                                                <option value='17'>Decimo séptimo proyecto</option>
                                                                <option value='18'>Decimo octavo proyecto</option>
                                                                <option value='19'>Decimo noveno proyecto</option>
                                                                <option value='20'>Duo decimo proyecto</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='titulo'>Título</label>
                                                        <input type='text' className='form-control' id='titulo' aria-describedby='textHelp'
                                                            value={this.state.titulo} onChange={this.handleInputChange.bind(this)}
                                                            placeholder={proyecto.titulo} />
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 segundo'>
                                                    <div className='form-group'>
                                                        <label htmlFor='descripcion'>Descripción</label>
                                                        <textarea type='text' rows='4' className='form-control' id='descripcion' aria-describedby='textHelp'
                                                            value={this.state.descripcion} onChange={this.handleInputChange.bind(this)}
                                                            placeholder={proyecto.descripcion} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <button className='btn cancelar' onClick={this.cancelar.bind(this)}>CANCELAR</button>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <button className='btn actualizar' id={proyecto.id} onClick={this.actualizar.bind(this)}>ACTUALIZAR</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : null
            ) : (
                    <div className='seccion-loading'>
                        <div className="d-flex justify-content-center">
                            <div className='spinner-border' role='status' style={{ display: loadingproyecto || loadingmessage ? 'block' : 'none' }}>
                                <span className='sr-only'>Loading...</span>
                            </div >
                        </div >
                    </div >
                )
        )

        let markuplista = (
            !loadingproyectos && !loadingmessage && proyectos ? (
                proyectos.map((datos, index) => {
                    return (
                        <div key={index} className='row' style={{ backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#f7f7f7' }}>
                            <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1'>
                                <p>{index + 1}</p>
                            </div>
                            <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'>
                                <img src={datos.image[0].imageUrl} alt='imagen' className='imagen-mostrar' />
                            </div>
                            <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                                <p>{datos.titulo}</p>
                            </div>
                            <div className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-3'>
                                <p>{datos.descripcion}</p>
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={view} alt='ver' id={datos.id} className='imagen-edit' onClick={this.verproyecto.bind(this)} />
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={editar} alt='ver' id={datos.id} className='imagen-edit' onClick={this.editarproyoecto.bind(this)} />
                            </div>
                            <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'>
                                <img src={borrar} alt='ver' id={datos.id} className='imagen-edit' onClick={this.borrarproyecto.bind(this)} />
                            </div>
                            <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                        </div>
                    )
                })
            ) : (
                    <div className='seccion-loading'>
                        <div className="d-flex justify-content-center">
                            <div className='spinner-border' role='status' style={{ display: loadingproyectos || loadingmessage ? 'block' : 'none' }}>
                                <span className='sr-only'>Loading...</span>
                            </div >
                        </div >
                    </div >
                )
        )

        return (
            <div className='lista-config datos-empresa'>
                <div className='overflow-auto'>
                    <div className='card-config'>
                        <div className='card shadow-sm p-3 mb-5 bg-light rounded'>
                            <div className='titulo' style={{ display: proyectos && proyectos.length > 0 ? 'block' : 'none' }}>
                                <div className='row'>
                                    <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2'>
                                        <p><strong>Imágen</strong></p>
                                    </div>
                                    <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2'>
                                        <p><strong>Título</strong></p>
                                    </div>
                                    <div className='col-5 col-sm-5 col-md-3 col-lg-3 col-xl-3'>
                                        <p><strong>Descripción</strong></p>
                                    </div>
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1' />
                                    <div className='col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1' />
                                </div>
                            </div>
                            <div className='lista'>
                                {markuplista}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id='cardverproyecto'>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className='overflow-auto'>
                                {markupverproyecto}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id='cardeditarproyecto'>
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className='overflow-auto'>
                                {markupeditarproyecto}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    proyectos: state.proyectos,
    proyecto: state.proyecto,
    message: state.message,
    image: state.image
})

ListaProyectos.propTypes = {
    proyectos: PropTypes.object.isRequired,
    proyecto: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    obtenerWebAdminProyectos: PropTypes.func.isRequired,
    obtenerWebAdminProyecto: PropTypes.func.isRequired,
    actualizarWebAdminProyecto: PropTypes.func.isRequired,
    borrarWebAdminProyecto: PropTypes.func.isRequired,
    borrarWebAdminImagenTemporal: PropTypes.func.isRequired,
    nuevaImagenWebAdmin: PropTypes.func.isRequired
}

const mapActionsToProps = {
    obtenerWebAdminProyectos,
    obtenerWebAdminProyecto,
    actualizarWebAdminProyecto,
    borrarWebAdminProyecto,
    nuevaImagenWebAdmin,
    borrarWebAdminImagenTemporal
}

export default connect(mapStateToProps, mapActionsToProps)(ListaProyectos)