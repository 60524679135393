import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import view from '../../images/iconos/view.png'
import editar from '../../images/iconos/editar.png'

import { connect } from 'react-redux'
import {
    obtenerWebAdminDatosAdmin,
    actualizarWebAdminDatosAdmin
} from '../../redux/actions/dataActions'

var $ = window.$

class ListaDatosAdmin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            empresa: '',
            direccion: '',
            fijo: '',
            celular: '',
            correo: '',
            facebook: '',
            instagram: '',
            linkedin: '',
            twitter: ''
        }
    }

    componentWillMount() {
        this.props.obtenerWebAdminDatosAdmin()
    }

    editardatoempresa(e) {
        e.preventDefault()

        $('#cardeditardatos').modal('toggle')
    }

    actualizar(e) {
        e.preventDefault()

        const { admin: { admin } } = this.props

        let iddatos = e.target.id

        let data = {
            empresa: this.state.empresa === '' ? admin.empresa : this.state.empresa,
            fijo: this.state.fijo === '' ? admin.fijo : this.state.fijo,
            celular: this.state.celular === '' ? admin.celular : this.state.celular,
            correo: this.state.correo === '' ? admin.correo : this.state.correo,
            direccion: this.state.direccion === '' ? admin.direccion : this.state.direccion,
            facebook: this.state.facebook === '' ? admin.facebook : this.state.facebook,
            instagram: this.state.instagram === '' ? admin.instagram : this.state.instagram,
            linkedin: this.state.linkedin === '' ? admin.linkedin : this.state.linkedin,
            twitter: this.state.twitter === '' ? admin.twitter : this.state.twitter
        }

        console.log(data)

        this.props.actualizarWebAdminDatosAdmin(data, iddatos)

        this.setState(update(this.state, {
            empresa: { $set: '' },
            direccion: { $set: '' },
            fijo: { $set: '' },
            celular: { $set: '' },
            correo: { $set: '' },
            facebook: { $set: '' },
            instagram: { $set: '' },
            linkedin: { $set: '' },
            twitter: { $set: '' }
        }))

        $('#cardeditardatos').modal('toggle')
    }

    cancelar(e) {
        e.preventDefault()

        $('#cardeditardatos').modal('toggle')
    }

    handleInputChange(e) {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value

        this.setState(update(this.state, {
            [id]: { $set: value }
        }))
    }

    render() {
        const { admin: { admin, loadingadmin } } = this.props
        const { message: { message, loadingmessage } } = this.props

        let markupeditardatos = (
            !loadingadmin && !loadingmessage ? (
                admin ? (
                    <div className='card-info-config admin'>
                        <div className='card'>
                            <div className='card-header'>
                                <h5>{admin.empresa}</h5>
                                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <form onSubmit={this.actualizar.bind(this)}>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <div className='form-group'>
                                                <label htmlFor='titulo'>Empresa</label>
                                                <input type='text' className='form-control' id='empresa' aria-describedby='textHelp'
                                                    value={this.state.empresa} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.empresa} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='celular'>Teléfono celular</label>
                                                <input type='number' className='form-control' id='celular' aria-describedby='numberHelp'
                                                    value={this.state.celular} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.celular} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='fijo'>Teléfono fijo</label>
                                                <input type='number' className='form-control' id='fijo' aria-describedby='numberHelp'
                                                    value={this.state.fijo} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.fijo} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='correo'>Correo electrónico</label>
                                                <input type='email' className='form-control' id='correo' aria-describedby='emailHelp'
                                                    value={this.state.correo} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.correo} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='direccion'>Ubicación</label>
                                                <input type='text' className='form-control' id='direccion' aria-describedby='textHelp'
                                                    value={this.state.direccion} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.direccion} />
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <div className='form-group'>
                                                <label htmlFor='facebook'>Link del facebook</label>
                                                <input type='text' className='form-control' id='facebook' aria-describedby='textHelp'
                                                    value={this.state.facebook} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.facebook} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='instagram'>Link del instagram</label>
                                                <input type='text' className='form-control' id='instagram' aria-describedby='textHelp'
                                                    value={this.state.instagram} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.instagram} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='twitter'>Link del twitter</label>
                                                <input type='email' className='form-control' id='twitter' aria-describedby='emailHelp'
                                                    value={this.state.twitter} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.twitter} />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='linkedin'>Link del linkedin</label>
                                                <input type='text' className='form-control' id='linkedin' aria-describedby='textHelp'
                                                    value={this.state.linkedin} onChange={this.handleInputChange.bind(this)}
                                                    placeholder={admin.linkedin} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                                            <button className='btn cancelar' onClick={this.cancelar.bind(this)}>CANCELAR</button>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                                            <button className='btn actualizar' id={admin.id} onClick={this.actualizar.bind(this)}>ACTUALIZAR</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : null
            ) : (
                    <div className='seccion-loading'>
                        <div className="d-flex justify-content-center">
                            <div className='spinner-border' role='status' style={{ display: loadingadmin || loadingmessage ? 'block' : 'none' }}>
                                <span className='sr-only'>Loading...</span>
                            </div >
                        </div >
                    </div >
                )
        )

        let markuplista = (
            !loadingadmin && !loadingmessage && admin ? (
                <div className='datos-admin'>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                            <div className='row'>
                                <p><strong>Empresa: </strong>{admin.empresa}</p>
                            </div>
                            <div className='row'>
                                <p><strong>Teléfono celular: </strong>{admin.celular}</p>
                            </div>
                            <div className='row'>
                                <p><strong>Teléfono fijo: </strong>{admin.fijo}</p>
                            </div>
                            <div className='row'>
                                <p><strong>Correo electrónico: </strong>{admin.correo}</p>
                            </div>
                            <div className='row'>
                                <p><strong>Ubicación: </strong>{admin.direccion}</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                            <div className='row'>
                                <p><strong>Facebook: </strong>{admin.facebook}</p>
                            </div>
                            <div className='row'>
                                <p><strong>Instagram: </strong>{admin.instagram}</p>
                            </div>
                            <div className='row'>
                                <p><strong>Twitter: </strong>{admin.twitter}</p>
                            </div>
                            <div className='row'>
                                <p><strong>Linkedin: </strong>{admin.linkedin}</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 izq'>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 der'>
                            <button className='btn actualizar' id={admin.id} onClick={this.editardatoempresa.bind(this)}>ACTUALIZAR</button>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className='seccion-loading'>
                        <div className="d-flex justify-content-center">
                            <div className='spinner-border' role='status' style={{ display: loadingadmin || loadingmessage ? 'block' : 'none' }}>
                                <span className='sr-only'>Loading...</span>
                            </div >
                        </div >
                    </div >
                )
        )

        return (
            <div className='lista-config datos-empresa'>
                <div className='overflow-auto'>
                    <div className='card-config'>
                        <div className='card shadow-sm p-3 mb-5 bg-light rounded'>
                            <div className='lista'>
                                {markuplista}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex="-1" role="dialog" id='cardeditardatos'>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className='overflow-auto'>
                                {markupeditardatos}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    admin: state.admin,
    message: state.message
})

ListaDatosAdmin.propTypes = {
    admin: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    obtenerWebAdminDatosAdmin: PropTypes.func.isRequired,
    actualizarWebAdminDatosAdmin: PropTypes.func.isRequired
}

const mapActionsToProps = {
    obtenerWebAdminDatosAdmin,
    actualizarWebAdminDatosAdmin
}

export default connect(mapStateToProps, mapActionsToProps)(ListaDatosAdmin)