import { browserHistory } from 'react-router'
import { getUserData } from './dataActions'
import axios from 'axios'

import {
    LOADING_DATA_USER,
    SET_DATA_USER,
    SET_ERROR_USER,
    SET_UNAUTHENTICATED,
    SET_USER
} from '../types'

const firebase = require('firebase')

firebase.initializeApp({
    apiKey: "AIzaSyAFd1iIHcj4tMvAyFRppBM1i6tYuMi5vjM",
    authDomain: "qoxecha-admin.firebaseapp.com",
    projectId: "qoxecha-admin",
    storageBucket: "qoxecha-admin.appspot.com",
    messagingSenderId: "35910729619",
    appId: "1:35910729619:web:c8c5ad86ec31df1efcf266",
    measurementId: "G-SPWY0TMRFT"
})

export const loginUser = (datasesion) => (dispatch) => {
    dispatch({ type: LOADING_DATA_USER })

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
        .then(() => {
            firebase
                .auth()
                .signInWithEmailAndPassword(datasesion.correo, datasesion.contrasenia)
                .then((data) => {
                    return data.user.getIdToken()
                }).then((token) => {
                    console.log(token)
                    setAuthorizationHeader(token)
                    dispatch({
                        type: SET_DATA_USER,
                        payload: token
                    })
                    browserHistory.push('/')
                    window.localStorage.setItem('token', token)
                }).catch((err) => {
                    dispatch({
                        type: SET_ERROR_USER,
                        payload: err.data
                    })
                })
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_USER,
                payload: err.data
            })
        })
}

export const logoutUser = () => (dispatch) => {
    window.localStorage.removeItem('token')
    delete axios.defaults.headers.common['Authorization']
    dispatch({ type: SET_UNAUTHENTICATED })
    browserHistory.push('/login')
}

const setAuthorizationHeader = (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}