import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import { browserHistory } from 'react-router'

import { connect } from 'react-redux'
import {
    nuevoWebAdminSlider, nuevaImagenTemporal, borrarWebAdminImagenTemporal
} from '../../redux/actions/dataActions'

import gallery from '../../images/iconos/gallery.png'
import borrar from '../../images/iconos/borrar.png'

var $ = window.$

class NuevoSlider extends React.Component {
    constructor (props){
        super (props)
        this.state = {
            titulo: '',
            subtitulo: '',
            image: '',
            indice: ''
        }
    }

    guardarslider (e){
        e.preventDefault ()
        const {images: {images}} = this.props

        let slider = this.state 
        
        if (slider.indice === '' || images.length === 0){
                alert('Debe completar todos los campos obligatorios (*)')
        }else{
            let data = {
                titulo: slider.titulo,
                subtitulo: slider.subtitulo,
                indice: slider.indice,
                image: images
            }

            console.log (data)
            this.props.nuevoWebAdminSlider (data)

            this.setState(update(this.state, {
                titulo: { $set: '' },
                subtitulo: { $set: '' },
                image: { $set: '' },
                indice: { $set: '' }
            }))

            window.scrollTo (0, 0)
        }
    }

    handleInputChange (e){
        e.preventDefault ()

        let id = e.target.id
        let value = e.target.value

        if (id === 'indice'){
            if (value !== '0') {
                this.setState(update(this.state, {
                    indice: { $set: value }
                }))
            }
        }else{
            this.setState (update (this.state, {
                [id]: {$set: value}
            }))
        }
    }

    handleImageChange(e) {
        const image = e.target.files[0]
        const formData = new FormData()
        formData.append('image', image, image.name)

        this.props.nuevaImagenTemporal(formData)
    }

    handleEditPicture(e) {
        const fileInput = document.getElementById('imageInput')
        fileInput.click()
    }

    borrarslider(e) {
        e.preventDefault()

        let id = e.target.id.split('/')[7].split('.')[0]

        this.props.borrarWebAdminImagenTemporal(id)
    }

    render() {
        const { message: { message, loadingmessage } } = this.props
        const { images: { images, loadingimages } } = this.props

        let slider = this.state

        let markupform = (
            !loadingmessage  ? (
                <div className='card shadow-none p-3 mb-5 bg-light rounded'>
                    <div className='form nuevo-config' onSubmit={this.guardarslider.bind (this)}>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                                <div className='principal'>
                                    <img src={gallery} alt='anuncio' />
                                    < input href='#' hidden='hidden' className='btn' type='file' accept='.gif, .jpg, .jpeg, .png'
                                        id='imageInput' onChange={this.handleImageChange.bind(this)} />
                                    <button className='btn imagen' id='imagen' type='button' onClick={this.handleEditPicture.bind(this)}>SELECCIONAR IMAGEN</button>
                                </div>
                                {
                                    !loadingimages && images ? (
                                        images.length > 0 ? (
                                            images.map((datos, index) => {
                                                return (
                                                    <div className='imagen' key={index}>
                                                        <img key={index} className='img' src={datos.imageUrl} id={datos.id} alt='imagen' />
                                                        <img src={borrar} className='borrar-img' id={datos.imageUrl} onClick={this.borrarslider.bind(this)} />
                                                    </div>
                                                )
                                            })
                                        ) : (null)
                                    ) : (
                                            <div className='seccion-loading'>
                                                <div className="d-flex justify-content-center">
                                                    <div className='spinner-border' style='width: 5rem; height: 5rem;' role='status' style={{ display: loadingimages ? 'block' : 'none' }}>
                                                        <span className='sr-only'>Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                            <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8'>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 label'>
                                        <label><strong>Indice del slider (*)</strong></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 primero'>
                                        <div className='form-group primero'>
                                            <select className='form-control' id='indice' onChange={this.handleInputChange.bind(this)}>
                                                <option defaultValue='0'>Seleccionar orden</option>
                                                <option value='01'>Primer slider</option>
                                                <option value='02'>Segundo slider</option>
                                                <option value='03'>Tercer slider</option>
                                                <option value='04'>Cuarto slider</option>
                                                <option value='05'>Quinto slider</option>
                                                <option value='06'>Sexto slider</option>
                                                <option value='07'>Séptimo slider</option>
                                                <option value='08'>Octavo slider</option>
                                                <option value='09'>Noveno slider</option>
                                                <option value='10'>Decimo slider</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6  label'>
                                        <label><strong>Título</strong></label>
                                    </div>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6  label'>
                                        <label><strong>Sub título</strong></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 primero'>
                                        <div className='form-group'>
                                            <input type='text' className='form-control' id='titulo' aria-describedby='textHelp'
                                                value={slider.titulo} onChange={this.handleInputChange.bind(this)}
                                                placeholder='Título del slider' />
                                        </div>
                                    </div>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 segundo'>
                                        <div className='form-group'>
                                            <input type='text' className='form-control' id='subtitulo' aria-describedby='textHelp'
                                                value={slider.subtitulo} onChange={this.handleInputChange.bind(this)}
                                                placeholder='Subtitulo del slider' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8'>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                                <button className='btn guardar' onClick={this.guardarslider.bind(this)}>GUARDAR SLIDER</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='seccion-loading'>
                    <div className="d-flex justify-content-center">
                        <div className='spinner-border' role='status' style={{ display: loadingmessage ? 'block' : 'none' }}>
                            <span className='sr-only'>Loading...</span>
                        </div >
                    </div >
                </div >
            )
        )

        return (
            <div>
                {markupform}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    message: state.message,
    images: state.images,
})

NuevoSlider.propTypes = {
    message: PropTypes.object.isRequired,
    images: PropTypes.object.isRequired,
    nuevoWebAdminSlider: PropTypes.func.isRequired,
    nuevaImagenTemporal: PropTypes.func.isRequired,
    borrarWebAdminImagenTemporal: PropTypes.func.isRequired
}

const mapActionsToProps = {
    nuevoWebAdminSlider,
    nuevaImagenTemporal,
    borrarWebAdminImagenTemporal
}

export default connect(mapStateToProps, mapActionsToProps)(NuevoSlider)