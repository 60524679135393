import React from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router'

import './styles.css'
import jwtDecode from 'jwt-decode'

import AuthRoute from './util/AuthRoute'

import { SET_AUTHENTICATED } from './redux/types'
import { logoutUser } from './redux/actions/userActions'

import AdminApp from './components/adminapp'
import Login from './components/login'

import Dashboard from './components/dashboard'

import Sliders from './components/sliders/sliders'
import NuevoSlider from './components/sliders/nuevoslider'
import ListaSliders from './components/sliders/listasliders'

import Servicios from './components/servicios/servicios'
import NuevoServicio from './components/servicios/nuevoservicio'
import ListaServicios from './components/servicios/listaservicios'

import DatosAdmin from './components/admin/datosadmin'
import NuevoDatoAdmin from './components/admin/nuevodatoadmin'
import ListaDatosAdmin from './components/admin/listadatosadmin'

import Proyectos from './components/proyectos/proyectos'
import NuevoProyecto from './components/proyectos/nuevoproyecto'
import ListaProyectos from './components/proyectos/listaproyectos'

import { Provider } from 'react-redux'
import store from './redux/store'

import axios from 'axios'

axios.defaults.baseURL = "https://us-central1-qoxecha-admin.cloudfunctions.net/admin"

let token = window.localStorage.getItem('token')

window.localStorage.removeItem('token')
if (token) {
  store.dispatch({ type: SET_AUTHENTICATED })
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
} else {
  store.dispatch(logoutUser())
}
 
class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={browserHistory}>

          <Route path='login' component={Login} />

          <AuthRoute path='/' component={AdminApp}>
            <IndexRoute path='' component={Dashboard} />

            <Route path='contacto' component={DatosAdmin}>
              <IndexRoute path='' component={ListaDatosAdmin} tab='lista-datos-admin' />
              <Route path='nuevo-dato-admin' component={NuevoDatoAdmin} tab='nuevo-dato-admin' />
            </Route>

            <Route path='sliders' component={Sliders}>
              <IndexRoute path='' component={ListaSliders} tab='lista-sliders' />
              <Route path='nuevo-slider' component={NuevoSlider} tab='nuevo-slider' />
            </Route>

            <Route path='proyectos' component={Proyectos}>
              <IndexRoute path='' component={ListaProyectos} tab='lista-proyectos' />
              <Route path='nuevo-proyecto' component={NuevoProyecto} tab='nuevo-proyecto' />
            </Route>

            <Route path='servicios' component={Servicios}>
              <IndexRoute path='' component={ListaServicios} tab='lista-servios' />
              <Route path='nuevo-servicio' component={NuevoServicio} tab='nuevo-servicio' />
            </Route>

          </AuthRoute>

        </Router>
      </Provider >

    )
  }
}

export default App